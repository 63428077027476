export const environment = {
  production: true,
  environment: '${ENVIRONMENT}',
  baseApiUrl: '${BASE_API_URL}',
  localApiUrl: '${LOCAL_API_URL}',
  google: {
    mapsApiKey: '${GOOGLE_MAPS_API_KEY}',
  },
};
environment.production = environment.environment === 'production';
