import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'hirit-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input()
  title!: string;
  @Input()
  actionsTemplate!: TemplateRef<unknown>;

  ngOnInit(): void {}
}
