import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';

import { SNACK_BAR_DEFAULT_OPTIONS } from '@utils/consts/snack-bar-default-options.const';

@Injectable()
export class SnackBarService {
  constructor(public snackBar: MatSnackBar) {}

  private open(
    message: string,
    type: 'success' | 'error' | 'info' | 'warning',
    action?: string,
    config?: MatSnackBarConfig,
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, {
      ...(config ?? SNACK_BAR_DEFAULT_OPTIONS),
      panelClass: ['snack-bar', `snack-bar-${type}`],
    });
  }

  openSuccess(message: string, action?: string, config?: MatSnackBarConfig) {
    return this.open(message, 'success', action, config);
  }

  openError(message: string, action?: string, config?: MatSnackBarConfig) {
    return this.open(message, 'error', action, config);
  }

  openInfo(message: string, action?: string, config?: MatSnackBarConfig) {
    return this.open(message, 'info', action, config);
  }

  openWarning(message: string, action?: string, config?: MatSnackBarConfig) {
    return this.open(message, 'warning', action, config);
  }
}
