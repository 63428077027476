import {
  AfterViewInit,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[fileUpload]',
  exportAs: 'fileUpload',
})
export class FileUploadDirective implements AfterViewInit, OnChanges {
  @Output() fileToUpload = new EventEmitter<File>();
  @Input()
  typesToAccept = 'image/jpeg, image/png, image/jpg';
  @Input()
  inputFile!: HTMLInputElement;
  private allowedExtensions!: RegExp;

  constructor(
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.allowedExtensions = new RegExp(
      `(${this.typesToAccept.replace(/, /g, '|')})$`,
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['typesToAccept']) {
      this.typesToAccept = this.typesToAccept.toLowerCase();
      this.allowedExtensions = new RegExp(
        `(${this.typesToAccept.replace(/, /g, '|')})$`,
      );
    }
  }

  @HostListener('click')
  onClick() {
    this.inputFile.click();
  }

  ngAfterViewInit(): void {
    this.insertInputInDom();
  }

  insertInputInDom(): void {
    if (!this.inputFile) {
      this.inputFile = this.renderer.createElement('input');
      const element = this.viewContainerRef.element.nativeElement;
      const parentContainer = this.renderer.parentNode(element);
      parentContainer.appendChild(this.inputFile);
    }
    this.inputFile.type = 'file';
    this.inputFile.accept = this.typesToAccept;
    this.inputFile.style.display = 'none';
    this.fileUpload();
  }

  fileUpload(): void {
    const fileUpload = this.inputFile;
    fileUpload.onchange = () => {
      const file = fileUpload.files?.item(0);
      if (file) {
        if (!this.allowedExtensions.test(file.type.toLowerCase())) {
          fileUpload.value = '';
        } else {
          this.fileToUpload.emit(file);
        }
      }
    };
  }

  clearInputFile(): void {
    this.inputFile.value = '';
  }
}
