<mat-toolbar color="primary" class="mat-elevation-z2">
  <mat-toolbar-row>
    <button
      mat-icon-button
      color="accent"
      (click)="drawer.toggle()"
      *ngIf="showSidenav &&(isHandset$ | async)">
     <mat-icon>menu</mat-icon>
    </button>
    <span *ngIf="title; else notTitle"> {{ title }}</span>
    <ng-template #notTitle>
      <img [src]="icon" [alt]="alternativeText">
    </ng-template>
    <span class="spacer"></span>
    <ng-content select="[mainRowContentLeft]"></ng-content>
  </mat-toolbar-row>
  <ng-content select="[toolbarContent]"></ng-content>
</mat-toolbar>
