<mat-sidenav-container
  [hasBackdrop]="(isHandset$ | async)">
  <mat-sidenav
    #drawer
    *ngIf="showSidenav"
    [fixedInViewport]="true"
    fixedTopGap="56"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [opened]="(isHandset$ | async) === false"
    [mode]="(isHandset$ | async) ? 'over' : 'side'">
    <ng-content select="[sidenav]"></ng-content>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'handset': (isHandset$ | async)}">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
