import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  entityName: string;
}

@Component({
  selector: 'hirit-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  /**
   * Event emitter to notify that the user has confirmed the deletion
   **/
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Constructor
   *
   * @param {MAT_DIALOG_DATA} data Data to be used in the dialog
   **/
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}
}
