<hirit-toolbar
  [icon]="icon"
  [title]="title"
  [alternativeText]="alternativeText"
  [isHandset$]="isHandset$"
  [drawer]="sidenav.drawer"
  [showSidenav]="showSidenav">
  <ng-content mainRowContentLeft select="[mainRowContentLeft]"></ng-content>
  <ng-content toolbarContent select="[toolbarContent]"></ng-content>
</hirit-toolbar>
<hirit-sidenav
  #sidenav
  [isHandset$]="isHandset$"
  [showSidenav]="showSidenav">
    <ng-content sidenav select="[sidenav]"></ng-content>
    <ng-content></ng-content>
</hirit-sidenav>
