<div class="mat-dialog-header">
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
</div>
<div mat-dialog-content>
  <ng-content select="[dialogContent]">
  </ng-content>
</div>
<div mat-dialog-actions>
  <ng-container [ngTemplateOutlet]="actionsTemplate ? actionsTemplate : defaultAction">
  </ng-container>
</div>
<ng-template #defaultAction>
  <button
    color="primary"
    class="rounded"
    mat-dialog-close
    mat-raised-button>
    {{ 'global.buttons.ok' | translate }}
  </button>
</ng-template>
