import {
  Directive,
  EventEmitter,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';

import { MapService } from '@manage/services/map/map.service';
import { Address } from '@shared/models/address.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[searchPlaces]',
  exportAs: 'searchPlaces',
})
export class SearchPlacesDirective implements OnInit {
  @Output()
  foundPlaces = new EventEmitter<Address[]>();

  constructor(
    @Optional()
    private ngControl: NgControl,
    private mapService: MapService,
    httpClient: HttpClient,
  ) {
    httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.google.mapsApiKey}&libraries=places`,
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      )
      .subscribe();
  }

  ngOnInit(): void {
    if (this.ngControl?.valueChanges) {
      this.ngControl.valueChanges
        .pipe(
          debounceTime(300),
          filter(
            (value: string) =>
              !!value && typeof value === 'string' && value.length > 2,
          ),
          switchMap((value: string) => this.mapService.getPredictions(value)),
          catchError((error) => {
            console.error(error);
            return of([]);
          }),
          map((predictions: google.maps.places.AutocompletePrediction[]) => {
            return predictions.map(
              ({
                description,
                structured_formatting: structuredFormatting,
                place_id: placeId,
              }) => {
                const address = new Address(description, placeId);
                address.mainText = structuredFormatting.main_text;
                address.secondaryText = structuredFormatting.secondary_text;
                return address;
              },
            );
          }),
        )
        .subscribe({
          next: (value: Address[]) => {
            this.foundPlaces.emit(value);
          },
        });
    }
  }
}
