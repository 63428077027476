<mat-card>
  <div class="mat-card-header">
    <ng-container [ngTemplateOutlet]="headerTemplate ? headerTemplate : defaultHeader">
    </ng-container>
  </div>
  <mat-card-content>
    <ng-content select="[cardContent]">
    </ng-content>
  </mat-card-content>
  <mat-card-actions>
    <ng-content select="[cardActions]">
    </ng-content>
  </mat-card-actions>
</mat-card>
<ng-template #defaultHeader>
  <mat-card-title>
    {{ title }}
  </mat-card-title>
  <mat-card-subtitle>
    {{ subtitle }}
  </mat-card-subtitle>
</ng-template>
