import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';
import es from 'date-fns/locale/es';

@Pipe({
  name: 'diffDates',
})
export class DiffDatesPipe implements PipeTransform {
  transform(
    referenceDate: number | Date | string,
    currentDate: number | Date | string = new Date(),
  ): string {
    const referenceDateAsDate = new Date(referenceDate);

    return formatDistance(currentDate as Date, referenceDateAsDate, {
      locale: es,
    });
  }
}
