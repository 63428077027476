import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { LayoutModule } from '@layout/layout.module';

import { CardComponent } from './components/card/card.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { FileUploadDirective } from './directives/file-upload/file-upload.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationRequiredDirective } from './directives/confirmation-required/confirmation-required.directive';
import { DiffDatesPipe } from './pipes/diff-dates.pipe';
import { SearchPlacesDirective } from './directives/search-places/search-places.directive';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [
    CardComponent,
    DialogComponent,
    ConfirmationDialogComponent,
    FileUploadDirective,
    ConfirmationRequiredDirective,
    DiffDatesPipe,
    SearchPlacesDirective,
  ],
  imports: [
    CommonModule,
    HttpClientJsonpModule,
    HttpClientModule,
    GoogleMapsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    LayoutModule,
    TranslateModule.forChild({
      extend: true,
    }),
    ReactiveFormsModule,
  ],
  exports: [
    CardComponent,
    MatAutocompleteModule,
    DialogComponent,
    ConfirmationDialogComponent,
    FileUploadDirective,
    ConfirmationRequiredDirective,
    SearchPlacesDirective,
    HttpClientJsonpModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSelectModule,
    MatRadioModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    LayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    DiffDatesPipe,
  ],
})
export class SharedModule {}
