import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LayoutComponent } from './components/layout/layout.component';

/**
 * @description
 * Layout Module
 *
 * @usageNotes
 * __Example__
 *
 * Example of a layout module that has a sidenav and toolbar component.
 *
 * ```html
 * <hirit-layout
 *   [showSidenav]="true">
 *   <div mainRowContentLeft>
 *     <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
 *       <mat-icon>favorite</mat-icon>
 *     </button>
 *   </div>
 *   <mat-toolbar-row toolbarContent>
 *     <!-- Content -->
 *   </mat-toolbar-row>
 *   <div sidenav>
 *     <mat-toolbar color="accent">Menu</mat-toolbar>
 *     <mat-nav-list>
 *       <a mat-list-item routerLink="/home" routerLinkActive="active">
 *         <mat-icon>home</mat-icon>
 *         Home
 *       </a>
 *       <a mat-list-item routerLink="/products" routerLinkActive="active">
 *         <mat-icon>store</mat-icon>
 *         Products
 *       </a>
 *     </mat-nav-list>
 *   </div>
 *   <router-outlet></router-outlet>
 * </hirit-layout>
 * ```
 **/
@NgModule({
  declarations: [ToolbarComponent, SidenavComponent, LayoutComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
  ],
  exports: [ToolbarComponent, SidenavComponent, LayoutComponent],
})
export class LayoutModule {}
