import { Component } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hirit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'hirit-bo';
  constructor(
    private translateService: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    translateService.setDefaultLang('es');
    this.addCustomIcons();
  }
  addCustomIcons(): void {
    this.matIconRegistry
      .addSvgIcon(
        'location',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/location.svg',
        ),
      )
      .addSvgIcon(
        'joystick',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/joystick.icon.svg',
        ),
      )
      .addSvgIcon(
        'users',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/users.icon.svg',
        ),
      )
      .addSvgIcon(
        'checks',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/check.icon.svg',
        ),
      );
  }
}
