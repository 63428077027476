import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '@home/services/env.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class EnvInterceptor implements HttpInterceptor {
  constructor(private envService: EnvService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let requestClone = request;
    if (environment.environment !== 'production') {
      if (this.envService.environment === 'local') {
        requestClone = request.clone({
          url: request.url.replace(
            environment.baseApiUrl,
            environment.localApiUrl,
          ),
        });
      }
    }
    return next.handle(requestClone);
  }
}
