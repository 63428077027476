import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  private environmentSubject: BehaviorSubject<string>;
  get environment$(): Observable<string> {
    return this.environmentSubject.asObservable();
  }

  get environment(): string {
    return this.environmentSubject.value;
  }

  set environment(value: string) {
    localStorage.setItem('environment', value);
    this.environmentSubject.next(value);
  }

  constructor() {
    this.environmentSubject = new BehaviorSubject(
      localStorage.getItem('environment') || environment.environment,
    );
  }
}
