import { Injectable } from '@angular/core';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { firstValueFrom } from 'rxjs';

/**
 * Service to interact with Google Maps Geocoder and Places API
 */
@Injectable({
  providedIn: 'root',
})
export class MapService {
  /**
   * Autocomplete service instance
   */
  private autoCompleteService!: google.maps.places.AutocompleteService;

  /**
   * @param geocoder - Google maps geocoder instance
   */
  constructor(private geocoder: MapGeocoder) {}

  /**
   * @description Get the autocomplete service instance
   */
  get autocompleteService(): google.maps.places.AutocompleteService {
    if (!this.autoCompleteService) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
    }
    return this.autoCompleteService;
  }

  /**
   * Geocode an address
   *
   * @param address - Human readable address
   * @returns - Response from google maps geocoder
   */
  public async geocodeAddress(address: string): Promise<MapGeocoderResponse> {
    return firstValueFrom(this.geocoder.geocode({ address }));
  }

  /**
   * Geocode a location
   *
   * @param location - Location to get the address from
   * @returns - Response from google maps geocoder
   */
  public async geocodeLocation(
    location: google.maps.LatLngLiteral | google.maps.LatLng,
  ): Promise<MapGeocoderResponse> {
    return firstValueFrom(this.geocoder.geocode({ location }));
  }

  /**
   * Geocode a place id
   *
   * @param placeId - Place to get the address from
   * @returns - Response from google maps geocoder
   */
  public async geocodePlaceId(placeId: string): Promise<MapGeocoderResponse> {
    return firstValueFrom(this.geocoder.geocode({ placeId }));
  }

  /**
   * Get predictions from the autocomplete service
   *
   * @param valueToSearch - Value to search for in the autocomplete
   * @param location - Location to search around
   * @returns - Response from google maps autocomplete
   */
  public async getPredictions(
    valueToSearch: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise((resolve, reject) => {
      const request: google.maps.places.AutocompletionRequest = {
        input: valueToSearch,
        types: ['address'],
        componentRestrictions: {
          country: ['SV', 'AR', 'PE'],
        },
      };

      this.autocompleteService.getPlacePredictions(
        request,
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions ?? []);
          } else {
            reject(status);
          }
        },
      );
    });
  }

  /**
   * Get the closest places to the location
   *
   * @param location - Location to get the address from google maps
   * @param map - The google map instance
   * @param radius - Radius to search around the location
   * @returns - Response from google maps places api
   */
  public async getNearbyPlaces(
    location: google.maps.LatLngLiteral,
    map: google.maps.Map,
    radius = 5000,
  ): Promise<google.maps.places.PlaceResult[]> {
    return new Promise((resolve, reject) => {
      const placeService = new google.maps.places.PlacesService(map);
      const request = {
        radius,
        location,
        type: 'tourist_attraction',
        fields: ['geometry', 'formatted_address', 'name', 'place_id'],
      };
      placeService.nearbySearch(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          resolve(place);
        } else {
          reject(status);
        }
      });
    });
  }

  /**
   * Get the place details from the place id
   *
   * @param placeId - Place id to get the details from google maps
   * @param map - The google map instance
   * @returns - The place details
   */
  public async getPlaceDetails(
    placeId: string,
    map: google.maps.Map,
  ): Promise<google.maps.places.PlaceResult> {
    return new Promise((resolve, reject) => {
      const placeService = new google.maps.places.PlacesService(map);
      const request = {
        placeId,
        fields: ['geometry', 'formatted_address', 'name', 'place_id'],
      };
      placeService.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          resolve(place);
        } else {
          reject(status);
        }
      });
    });
  }
}
