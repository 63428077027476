export class Address {
  id?: string;
  mainText: string;
  description: string;
  secondaryText: string;
  placeId?: string;
  location: {
    lat: number;
    lng: number;
  };

  constructor(
    description?: string,
    placeId?: string,
    latitude?: number,
    longitude?: number,
    structuredFormatting?: string,
  ) {
    this.description = description || '';
    this.placeId = placeId || '';
    const parts = structuredFormatting?.split(', ');
    this.mainText = parts?.[0] ?? '';
    this.secondaryText = parts?.slice(1).join(', ') ?? '';
    this.location = {
      lat: latitude ?? 0,
      lng: longitude ?? 0,
    };
  }
}
