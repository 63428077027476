import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Observable } from 'rxjs';

@Component({
  selector: 'hirit-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  /**
   * Title of the toolbar
   **/
  @Input()
  title!: string;
  /**
   * Icon of the toolbar
   **/
  @Input()
  icon!: string;
  /**
   * Alternative text for the toolbar icon
   **/
  @Input()
  alternativeText!: string;
  /**
   * Drawer of the sidenav for open/close
   **/
  @Input() drawer!: MatDrawer;
  /**
   * Indicates if the device is a handset
   **/
  @Input() isHandset$!: Observable<boolean>;
  /**
   * Indicates if the sidenav should be shown
   **/
  @Input() showSidenav = true;
}
