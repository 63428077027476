<hirit-dialog
  [title]="'global.deleteDialog.title' | translate"
  [actionsTemplate]="customActions">
<ng-container ngProjectAs="[dialogContent]">
  <p
    [innerHTML]="'global.deleteDialog.warningMessage' | translate:{ name: data.entityName }">
  </p>
</ng-container>

</hirit-dialog>
<ng-template #customActions>
  <button
    type="button"
    color="accent"
    class="rounded"
    cdkFocusInitial
    mat-stroked-button
    [mat-dialog-close]="false">
    {{ 'global.deleteDialog.noCancel' | translate }}
  </button>
  <button
    color="warn"
    type="button"
    class="rounded"
    mat-raised-button
    [mat-dialog-close]="true">
    {{ 'global.deleteDialog.yesImSure' | translate }}
  </button>
</ng-template>
