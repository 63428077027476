import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { SidenavComponent } from '../sidenav/sidenav.component';

/**
 * @description
 * Reusable layout
 * @author Alejandro Mejía
 **/
@Component({
  selector: 'hirit-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  /**
   * Indicates if the device is a handset
   **/
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );
  /**
   * Title of the toolbar
   **/
  @Input() title!: string;
  /**
   * Icon of the toolbar
   **/
  @Input()
  icon!: string;
  /**
   * Alternative text for the toolbar icon
   **/
  @Input()
  alternativeText!: string;
  /**
   * Indicates if the sidenav should be shown
   **/
  @Input() showSidenav = true;
  /**
   * Sidenav componenet ref
   **/
  @ViewChild(SidenavComponent)
  sidenavComponent!: SidenavComponent;

  /**
   * Constructor
   *
   * @param {BreakpointObserver} breakpointObserver - Breakpoint observer
   **/
  constructor(private breakpointObserver: BreakpointObserver) {}

  toggle() {
    this.sidenavComponent?.drawer?.toggle();
  }
}
