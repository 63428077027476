import { Component, Input, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Observable } from 'rxjs';

/**
 * @description
 * Reusable sidenav
 * @author Alejandro Mejía
 **/
@Component({
  selector: 'hirit-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  /**
   * Drawer of the sidenav
   **/
  @ViewChild('drawer')
  drawer!: MatDrawer;
  /**
   * Indicates if the device is a handset
   **/
  @Input() isHandset$!: Observable<boolean>;
  /**
   * Indicates if the sidenav should be shown
   **/
  @Input() showSidenav = true;
}
