import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';

import { SnackBarService } from './services/snack-bar.service';

@NgModule({
  declarations: [],
  providers: [SnackBarService],
  imports: [CommonModule, SharedModule],
})
export class CoreModule {}
