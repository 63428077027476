/* eslint-disable @angular-eslint/directive-selector */
import { ElementRef, EventEmitter, HostListener, Input } from '@angular/core';
import { Directive, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';

/**
 * Directive to open a confirmation dialog.
 */
@Directive({
  selector: '[confirmationRequired]',
  exportAs: 'confirmationRequired',
})
export class ConfirmationRequiredDirective {
  /**
   * Name of the entity to be deleted.
   **/
  @Input() entityName = '';
  /**
   * Event to emit when the user clicks on the dialog's 'Yes' button.
   **/
  @Output() confirm = new EventEmitter<void>();
  /**
   * Referes to the dialog opened by this directive.
   **/
  private dialogRef!: MatDialogRef<ConfirmationDialogComponent>;

  /**
   * Constructor
   *
   * @param {ElementRef} hostRef - The element on which the directive is defined.
   * @param {MatDialog} matDialog - The dialog service.
   **/
  constructor(
    private matDialog: MatDialog,
    private hostRef: ElementRef<HTMLButtonElement>,
  ) {}

  /**
   * Opens the dialog when the user clicks on the element.
   **/
  @HostListener('click')
  open() {
    const rect: DOMRect = this.hostRef.nativeElement.getBoundingClientRect();

    this.dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      hasBackdrop: false,
      autoFocus: true,
      data: { entityName: this.entityName },
      position: { right: '10px', top: `${rect.bottom + 2}px` },
      maxWidth: '350px',
    });

    this.dialogRef.beforeClosed().subscribe({
      next: (confirm: boolean) => {
        if (confirm) {
          this.confirm.emit();
        }
      },
    });
  }
}
